import React from 'react';
import styles from './index.module.sass';

import logo from '../../images/logo_header.png';

const Loadingpage = (props) => {
    return <div id={styles.Loadingpage}>
        <div className={styles.logo}><img src={logo} alt='Terramar Logo' /></div>
        <div>LOADING...</div>
    </div>
};

export default Loadingpage;