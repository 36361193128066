import React from 'react';

// Styles
import styles from './index.module.sass';

export default function BlankSpace({ height = 16, backgroundColor = '#ffffff' }) {
  return (
    <div
      className={styles.BlankSpace}
      style={{
        backgroundColor,
        height: `${height}px`,
      }}></div>
  );
}
