import superagent from 'superagent';
import webservice from './webservice';
import getCookie from './getCookie';

export default async function postGlobal({ tmbservice, jsonBody }) {
  let response = {};
  await superagent
    .post(`${webservice}/${tmbservice}`)
    .send(jsonBody)
    .auth(getCookie('TMBcartToken'), { type: 'bearer' })
    .then((res) => {
      response = res?.body || {};
    })
    .catch((err) => {
      console.log(err);
      const resp = {
        error: err,
        tipoMensaje: 'error',
        mensaje: 'Error',
      };
      response = resp;
    });

  return response;
}
