import React, { useEffect, useState, useCallback } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

const MapContainer = (props) => {
  const { leaders = [], google, isAll } = props;
  const [markers, setMarkers] = useState([]);
  const [avgLat, setAvgLat] = useState(19.427142);
  const [avgLon, setAvgLon] = useState(-99.167658);
  const [zoom, setZoom] = useState(4);

  const createMarkers = useCallback(() => {
    let mrk = [];
    let avg_lat = 0;
    let avg_lon = 0;
    for (let i = 0; leaders.length > i; i++) {
      const pos = leaders[i].coordenadas && leaders[i].coordenadas.split(',');
      if (pos.length > 1) mrk.push(pos);
    }

    for (let i = 0; mrk.length > i; i++) {
      avg_lat += parseFloat(mrk[i][0].trim());
      avg_lon += parseFloat(mrk[i][1].trim());
    }

    setAvgLat((mrk.length > 0 && avg_lat / mrk.length) || 19.427142);
    setAvgLon((mrk.length > 0 && avg_lon / mrk.length) || -99.167658);
    setZoom((mrk.length < 1 && 4) || (isAll && 4) || 7);
    setMarkers(mrk);
  }, [leaders, isAll]);

  useEffect(() => {
    createMarkers();
  }, [createMarkers]);

  return (
    <div>
      <Map
        google={props.google}
        zoom={zoom}
        containerStyle={{
          position: 'relative',
          width: '100%',
          height: '400px',
        }}
        center={{ lat: avgLat, lng: avgLon }}>
        {markers.map((val, key) => {
          return (
            <Marker
              title={'The marker`s title will appear as a tooltip.'}
              position={{ lat: parseFloat(val[0].trim()), lng: parseFloat(val[1].trim()) }}
              key={key}
              icon={{
                url: 'https://webimages.terramarbrands.com.mx/webpage/joinup/mark.png',
              }}
              animation={google.maps.Animation.DROP}
            />
          );
        })}
      </Map>
    </div>
  );
};

//memo = not render if not necessary
export default React.memo(
  GoogleApiWrapper({
    apiKey: 'AIzaSyCw01lYx8iNlNYoeJe2dk-RepDGqoqf1EY',
  })(MapContainer),
);
