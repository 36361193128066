import React, { useState } from 'react';

// Styles
import styles from './index.module.sass';

import PLAYER_IMAGE from '../../images/home/video-play.svg';

export default function HeroWithVideo({
  screenSize = 'desktop',
  videoURL,
  videoType = 'video/mp4',
  titleText = '',
  subtitleText = '',
  buttonText = '',
  buttonCallback,
  youtubeVideo = '',
  videoThumb = '',
}) {
  const [showVideo, setShowVideo] = useState(false);

  function onClick() {
    if (buttonCallback) buttonCallback();
  }

  return (
    <div className={`${styles.HeroWithVideo} ${styles[screenSize]}`}>
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div className={styles.videoContainer}>
            {screenSize !== 'phone' && (
              <video autoPlay='autoplay' loop='loop' muted='muted' id={styles.myVideo}>
                <source src={videoURL} type={videoType} />
              </video>
            )}
            {screenSize === 'phone' && (
              <div
                className={styles.imageBG}
                style={{
                  backgroundImage: `url(${videoThumb})`,
                }}></div>
            )}
            {youtubeVideo && (
              <img
                src={PLAYER_IMAGE}
                alt=''
                className={styles.playImage}
                onClick={() => setShowVideo(true)}
              />
            )}
          </div>
          <div className={styles.textContainer}>
            <div className={styles.title}>{titleText}</div>
            <div className={styles.subtitle}>{subtitleText}</div>
            {screenSize !== 'phone' && (
              <div className={styles.button}>
                <div className={styles.buttonText} onClick={() => onClick()}>
                  {buttonText}
                </div>
              </div>
            )}
          </div>
        </div>
        {screenSize === 'phone' && (
          <div className={styles.textContainer}>
            <div className={styles.button}>
              <div className={styles.buttonText} onClick={() => onClick()}>
                {buttonText}
              </div>
            </div>
          </div>
        )}
      </div>
      {showVideo && youtubeVideo && (
        <div className={styles.youtubeContainer}>
          <div className={styles.overlay} onClick={() => setShowVideo(false)}></div>
          <div className={styles.closeVideo} onClick={() => setShowVideo(false)}>
            X
          </div>
          <div className={styles.videoCard}>
            <iframe
              width='95%'
              height='95%'
              src={youtubeVideo}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen='allowfullscreen'></iframe>
          </div>
        </div>
      )}
    </div>
  );
}
