// @flow

import React from 'react';

type ValidationInputEvent = 'change' | 'blur';

type Replacer<value> = (value) => string;

type CustomValidation<value> = (value) => boolean;

export type useInputErrors = {
  requiredError?: string,
  defaultError?: string,
  minlengthError?: string,
  maxlengthError?: string,
};

export type useInputProps = {|
  placeholder?: string,
  name: string,
  value?: string,
  errors?: useInputErrors,
  type?: string,
  spellCheck?: boolean,
  required?: boolean,
  +regexp?: ?string | ?RegExp,
  autoComplete?: boolean,
  +regexpOverwrite?: ?string | ?RegExp,
  label?: string,
  validateEvent?: ValidationInputEvent,
  toLowerCase?: boolean,
  toUpperCase?: boolean,
  +replacer?: ?Replacer<string>,
  +minlength?: ?number,
  +maxlength?: ?number,
  +customValidation?: ?CustomValidation<string>,
  disabled?: boolean,
  validateMinLengthOnBlur?: boolean,
  +maskRegex?: ?string | ?RegExp,
  +maskPermanents?: ?Array<number>,
  maskPermanentChar?: string,
  maskChar?: string,
  icon?: string,
  maskPrefill?: boolean,
|};

export type inputRef = {|
  disabled: boolean,
  error: any | string,
  onBlur: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  labelText: string,
  spellCheck: boolean,
  type: string,
  value: any | string,
  name: string,
  autoComplete: boolean,
  icon?: string,
  placeholder?: string,
|};

export type useInputRef = {|
  input: inputRef,
  setData: (((any) => any) | any) => void,
  type: string,
  validate: (config?: any) => boolean,
|};

export default function UseInput({
  // Nombre del campo en el formulario
  name,
  // Valor inicial
  value,
  // Objeto de errores a mostrar incluye required, default y min, ver + abajo
  errors = {},
  // Tipo del input
  type = 'text',
  // Activar o desactivar correciones ortograficas
  spellCheck = false,
  autoComplete = false,
  // Marcar como requerido
  required = false,
  // Expresion para activar errores
  regexp = null,
  // Expresion para remplazar
  regexpOverwrite = null,
  label = '',
  // Evento en cual validar ya sea change o blur
  validateEvent = '',
  // Mandar todo a minuscula
  toLowerCase = false,
  // Mandar todo a mayuscula
  toUpperCase = false,
  /**
   * Funcion para hacer un replace custom ej:
   * (valor) => { return nuevoValor }
   */
  replacer = null,
  minlength = null,
  maxlength = null,
  /**
   * Funcion para hacer un validacion personalizada:
   * (valor) => { return esValido; }
   */
  customValidation = null,
  // Desactivar interaccion en el input
  disabled = false,
  // Forzar validacion de longitud en blur
  validateMinLengthOnBlur = false,
  icon = '',
  placeholder = '',
}: useInputProps): useInputRef {
  const [data, setData] = React.useState({
    value: value || '',
    displayValue: value || '',
    error: '',
  });

  React.useEffect(() => {
    if (value)
      setData((oldState) => ({
        ...oldState,
        value: value || '',
        displayValue: value || '',
      }));
  }, [value]);

  const {
    requiredError = '',
    defaultError = '',
    minlengthError = '',
    maxlengthError = '',
  } = errors;

  const validate = (config: any = {}) => {
    const { avoidValidation = false } = config;
    let { value: inputValue } = data;
    let error = '';

    if (typeof inputValue === 'string') inputValue = inputValue.trim();

    if (required && !inputValue) error = requiredError;
    else if (minlength && minlength > inputValue.length && !!inputValue.length)
      error = minlengthError;
    else if (regexp && inputValue) {
      if (!new RegExp(regexp).test(inputValue)) error = defaultError;
    }
    if (customValidation) {
      if (!customValidation(inputValue)) error = defaultError;
    }
    if (!avoidValidation) setData((currentState) => ({ ...currentState, error }));
    return !!error;
  };

  const input = {
    name,
    disabled,
    spellCheck,
    labelText: label,
    type: type || 'text',
    autoComplete,
    icon,
    placeholder,
    onChange: (e: SyntheticInputEvent<HTMLInputElement>) => {
      let { value: targetValue } = e.target;
      let error = '';
      if (toLowerCase) targetValue = targetValue.toLowerCase();
      else if (toUpperCase) targetValue = targetValue.toUpperCase();

      if (maxlength && targetValue.length > maxlength) {
        targetValue = targetValue.substring(0, maxlength);
      }

      if (validateEvent === 'change') {
        if (maxlength && targetValue.length > maxlength) {
          error = maxlengthError;
        }
        if (required && !targetValue) error = requiredError;
        else if (regexp && targetValue) {
          if (!new RegExp(regexp).test(targetValue)) error = defaultError;
        }
        if (customValidation) {
          if (!customValidation(targetValue)) error = defaultError;
        }
      }

      if (regexpOverwrite) {
        targetValue = (targetValue.match(new RegExp(regexpOverwrite)) || []).join('');
      }

      const displayValue = targetValue;

      if (replacer) targetValue = replacer(targetValue);

      setData({ value: targetValue, displayValue, error });
    },
    onBlur: (e: SyntheticInputEvent<HTMLInputElement>) => {
      let { value: targetValue } = e.target;
      let error = '';

      if (validateEvent === 'blur') {
        if (maxlength && targetValue.length > maxlength) {
          targetValue = targetValue.substring(0, maxlength);
          error = maxlengthError;
        }
        if (required && !targetValue) error = requiredError;
        else if (minlength && minlength > targetValue.length) error = minlengthError;
        else if (regexp && targetValue) {
          if (!new RegExp(regexp).test(targetValue)) error = defaultError;
        }
        if (customValidation) {
          if (!customValidation(targetValue)) error = defaultError;
        }
      } else if (validateMinLengthOnBlur) {
        if (minlength && minlength > targetValue.length) error = minlengthError;
      }

      setData((state) => ({ ...state, value: targetValue, error }));
    },
    value: data.value,
    error: data.error,
  };

  return {
    input,
    setData,
    validate,
    type,
  };
}
