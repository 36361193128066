function normalizeArrayNames(str) {
  const [name] = str.split('.');

  return name;
}

function createErrors(fields) {
  return fields
    .flatMap((field) => {
      if (field?.input) return field.validate() && field;
      return null;
    })
    .filter(Boolean);
}

function createData(field) {
  if (!field) return null;
  if (field.fields) {
    let object = {};
    field.fields.forEach((localField) => {
      if (localField) {
        if (localField.name) {
          object = {
            ...object,
            [normalizeArrayNames(localField.name)]: localField.fields.map(({ fields }) =>
              createData({ fields }),
            ),
          };
        } else {
          const data = createData(localField);
          object = { ...object, ...data };
        }
      }
    });
    return object;
  }
  if (field.input) {
    let newValue = field.input.value;
    if (field.type === 'number') newValue = Number(field.input.value);
    if (typeof newValue === 'string') newValue = newValue.trim();
    return { [normalizeArrayNames(field.input.$$name || field.input.name)]: newValue };
  }
  return null;
}

export default function validateData(fields) {
  const errorsArray = createErrors(fields);
  const data = createData({ fields });
  const errors = (errorsArray.length && errorsArray) || undefined;
  return { data, errors };
}
