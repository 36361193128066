/* eslint-disable react/jsx-props-no-spreading */

// Libs
import * as React from 'react';

// CSS
import styles from './TextInput.module.sass';

// Flow
import type { inputRef } from '../../hooks/useInput';

type InputType = {
  +children?: ?React.Node,
  input: inputRef,
  inline: any,
  background: any,
  mode?: 'input' | 'textarea',
};

export default function TextField({
  children,
  input: {
    name,
    error,
    value,
    onBlur,
    onChange,
    labelText,
    type = 'text',
    disabled = false,
    spellCheck = false,
    autoComplete = false,
    placeholder = '',
  },
  background = '',
  inline = false,
  mode = 'input',
  ...props
}: InputType) {
  const [focus, setFocus] = React.useState(!!value || !!children);

  const isOpen = type === 'datetime-local' || type === 'date' || focus || !!value || !!children;

  function handleFocus() {
    setFocus(true);
  }

  function handleBlur(e) {
    e.persist();

    setFocus(false);

    onBlur(e);
  }

  const overrideStyles = {};
  const spanStyles = {};
  const inputStyles = {};

  if (disabled) {
    overrideStyles.background = 'var(--disabled-button-background)';
    overrideStyles.opacity = '0.6';
    overrideStyles.pointerEvents = 'none';
    overrideStyles.userSelect = 'none';
  }

  if (inline) {
    overrideStyles.backgroundColor = 'transparent';
    overrideStyles.borderRadius = '0';
    overrideStyles.borderTopWidth = '0';
    overrideStyles.borderLeftWidth = '0';
    overrideStyles.borderRightWidth = '0';
    spanStyles.left = '0';
    inputStyles.paddingLeft = '0';
  }

  if (background) {
    overrideStyles.backgroundColor = '#e0e5ec';
    spanStyles.backgroundColor = '#e0e5ec';
  }

  return (
    <>
      {labelText && (
        <div
          className={`${styles.text} ${isOpen ? styles.textOpen : ''} ${
            (!error && (focus ? styles.textActive : styles.textInactive)) || styles.textError
          }`}
          style={spanStyles}>
          {labelText}
        </div>
      )}
      <label
        aria-label={labelText}
        htmlFor={name}
        className={`${mode === 'textarea' ? styles.labelTextArea : styles.label} ${
          error ? styles.labelError : ''
        }`}
        style={overrideStyles}>
        {!children && mode === 'input' && (
          <input
            id={name}
            dir='auto'
            type={type}
            value={value}
            onBlur={handleBlur}
            disabled={disabled}
            onChange={onChange}
            onFocus={handleFocus}
            aria-invalid={!!error}
            aria-label={labelText}
            spellCheck={spellCheck}
            className={styles.input}
            autoComplete={autoComplete ? 'on' : 'off'}
            style={inputStyles}
            placeholder={placeholder}
            {...props}
          />
        )}
        {!children && mode === 'textarea' && (
          <textarea
            id={name}
            dir='auto'
            type={type}
            value={value}
            onBlur={handleBlur}
            disabled={disabled}
            onChange={onChange}
            onFocus={handleFocus}
            aria-invalid={!!error}
            aria-label={labelText}
            spellCheck={spellCheck}
            className={styles.textArea}
            autoComplete={autoComplete ? 'on' : 'off'}
            style={inputStyles}
            placeholder={isOpen ? placeholder : ''}
            {...props}
          />
        )}
        {!!children && (
          <select
            id={name}
            dir='auto'
            type={type}
            value={value}
            onBlur={handleBlur}
            disabled={disabled}
            onChange={onChange}
            onFocus={handleFocus}
            aria-invalid={!!error}
            aria-label={labelText}
            spellCheck={spellCheck}
            className={styles.select}
            {...props}>
            {children}
          </select>
        )}
      </label>
      {!!error && <div className={styles.errorDescription}>{error}</div>}
    </>
  );
}

TextField.defaultProps = {
  children: null,
  mode: 'input',
};
