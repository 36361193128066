import React from 'react';
import styles from './index.module.sass';

export default function sectionTitle({ title = '', subtitle = '', subtitleGray = '' }) {
  return (
    <div className={styles.sectionTitle}>
      <div className={styles.titleText}>{title}</div>
      {subtitle !== '' && <div className={styles.subtitleText}>{subtitle}</div>}
      {subtitleGray !== '' && <div className={styles.subtitleGrayText}>{subtitleGray}</div>}
    </div>
  );
}
