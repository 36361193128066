import React, { useState } from 'react';

// Styles
import styles from './index.module.sass';

export default function ThreeBanners({
  screenSize,
  urlOne,
  urlTop,
  urlBottom,
  oneMobile,
  topMobile,
  bottomMobile,
  oneVideo,
  topVideo,
  bottomVideo,
  oneTitle,
  oneText,
  topTitle,
  topText,
  bottomTitle,
  bottomText,
  onceCallback,
  topCallback,
  bottomCallback,
}) {
  const [showVideo, setShowVideo] = useState(false);
  const [youtubeVideo, setYoutubeVideo] = useState('');

  function handleVideo(url) {
    setShowVideo(true);
    setYoutubeVideo(url);
  }

  function onOneClick() {
    if (oneVideo) handleVideo(oneVideo);
    if (onceCallback) onceCallback();
  }

  function onTopClick() {
    if (topVideo) handleVideo(topVideo);
    else if (topCallback) topCallback();
  }

  function onBottomClick() {
    if (bottomVideo) handleVideo(bottomVideo);
    if (bottomCallback) bottomCallback();
  }

  return (
    <div className={`${styles.ThreeBanners} ${styles[screenSize]}`}>
      <div className={styles.ThreeBannersContainer}>
        <div className={styles.oneBanner}>
          <div className={styles.oneImage} onClick={() => onOneClick()}>
            <div
              className={styles.imageBG}
              style={{
                backgroundImage: `url(${(screenSize === 'phone' && oneMobile) || urlOne})`,
              }}
            />
            <div className={styles.bannerTextContainer}>
              <div className={styles.bannerTitle}>{oneTitle}</div>
              <div className={styles.bannerText}>{oneText}</div>
            </div>
          </div>
        </div>
        <div className={styles.twoBanners}>
          <div className={styles.bannerTop}>
            <div className={styles.topImage} onClick={() => onTopClick()}>
              <div
                className={styles.imageBG}
                style={{
                  backgroundImage: `url(${(screenSize === 'phone' && topMobile) || urlTop})`,
                }}
              />
              <div className={styles.bannerTextContainer}>
                <div className={styles.bannerTitle}>{topTitle}</div>
                <div className={styles.bannerText}>{topText}</div>
              </div>
            </div>
          </div>
          <div className={styles.bannerBottom}>
            <div className={styles.bottomImage} onClick={() => onBottomClick()}>
              <div
                className={styles.imageBG}
                style={{
                  backgroundImage: `url(${(screenSize === 'phone' && bottomMobile) || urlBottom})`,
                }}
              />
              <div className={styles.bannerTextContainer}>
                <div className={styles.bannerTitle}>{bottomTitle}</div>
                <div className={styles.bannerText}>{bottomText}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVideo && youtubeVideo && (
        <div className={styles.youtubeContainer}>
          <div className={styles.overlay} onClick={() => setShowVideo(false)}></div>
          <div className={styles.closeVideo} onClick={() => setShowVideo(false)}>
            X
          </div>
          <div className={styles.videoCard}>
            <iframe
              width='95%'
              height='95%'
              src={youtubeVideo}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen='allowfullscreen'></iframe>
          </div>
        </div>
      )}
    </div>
  );
}
