import superagent from 'superagent';
import webservice from './webservice';
import getCookie from './getCookie';

export default async function getGlobal({ tmbservice, jsonBody }) {
  const url = `${webservice}/${tmbservice}`;
  let response = {};

  await superagent
    .get(url)
    .set('Content-Type', 'application/json')
    .auth(getCookie('TMBcartToken'), { type: 'bearer' })
    .query(jsonBody)
    .then((res) => {
      response = res.body;
    })
    .catch((err) => {
      console.log(err);
      const resp = {
        ...jsonBody,
        error: err,
        tipoMensaje: 'error',
        mensaje: 'Error',
        typeMessage: 'error',
        message: 'Error',
      };
      response = resp;
    });

  return response;
}
