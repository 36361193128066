import React from 'react';
import styles from './index.module.sass';

const Title = (props) => {
  const { title = '', subtitle = '', device = {} } = props;
  const { screenSize = 'desktop' } = device;
  return (
    <div id={styles.Titles} className={styles[screenSize]}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  );
};

export default Title;
